export async function loadContent(key_content) {
    let response = await fetch('/data/' + key_content + ".json");
    let source = await response.json();
    // console.log("source===>", source.title);
    return source;
    // const response = await fetch('/data/' + key_content + ".json", {
    //     headers: {
    //         method: "GET",
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     }
    // });
    // let data = await response.json();
    // return data;
    // data = JSON.stringify(data);
    // data = JSON.parse(data);
}
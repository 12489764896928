// import { NavLink } from 'react-router-dom'; 
import React, { useEffect, useState } from 'react';
import CpResaleView from "../view_prod/cp_resale_view";
import chatAI from "./obChatAI"
var data_spAI = {};
var chatAI_ob = new chatAI();
function CpResale(props) {
    const [inputInfoAI, setInputInfoAI] = useState("");
    // khởi tạo in ra lỗi khi nhập inputInfoAI
    const [inputInfoAIError, setInputInfoAIError] = useState("");

    const [selectedProd, setSelectedProd] = useState("sp1");

    const [cpViewSpStatusSp1, setCpViewSpStatusSp1] = useState(false);
    const [cpViewSpStatusSp2, setCpViewSpStatusSp2] = useState(false);
    const [cpViewSpStatusSp3, setCpViewSpStatusSp3] = useState(false);

    const handleCp_inputInfoAI = (txt_) => {
        setInputInfoAIError("");
        setInputInfoAI(txt_);
        if (txt_.length > 300 && txt_.length <= 3000) {
            props.call_InputInfo(txt_);
        }
        else {
            let mess_r = props.data.form_input_2_title0_error[props.lang];
            mess_r = mess_r.replace("{1}", "" + txt_.length);
            setInputInfoAIError(mess_r);
        }
    }
    const handleCpSale1 = (key_, data_spAI_of_key) => {
        data_spAI[key_] = data_spAI_of_key;

        //vì useState có độ trễ nên cần khai báo vào biến trung gian
        let c_cpViewSpStatusSp1 = cpViewSpStatusSp1;
        let c_cpViewSpStatusSp2 = cpViewSpStatusSp2;
        let c_cpViewSpStatusSp3 = cpViewSpStatusSp3;

        if (key_ === "sp1") {
            setCpViewSpStatusSp1(true);
            c_cpViewSpStatusSp1 = true;
        }

        if (key_ === "sp2") {
            setCpViewSpStatusSp2(true);
            c_cpViewSpStatusSp2 = true;
        }

        if (key_ === "sp3") {
            setCpViewSpStatusSp3(true);
            c_cpViewSpStatusSp3 = true;
        }

        //set cho trang gọi 
        //luôn luôn cập nhập tất cả
        props.call_CpSale(data_spAI);

        props.call_CpSale_status(c_cpViewSpStatusSp1 || c_cpViewSpStatusSp2 || c_cpViewSpStatusSp3);
    }

    useEffect(() => {
        chatAI_ob.test("Ok a");
    }, []);
    return <div className="flex flex-wrap mb-4 -mx-3 wow animate__animated animate__fadeInUp animated" data-wow-delay=".3s">
        <div className="w-full lg:w-1 px-3 mb-12 lg:mb-10">
            <span className="mr-12 font-semibold">{props.data.form_input_2_title0[props.lang]}</span>
            <textarea value={inputInfoAI} onChange={e => handleCp_inputInfoAI(e.target.value)} className="w-full h-full p-4 text-xs font-semibold leading-none resize-none bg-blueGray-50 rounded outline-none" placeholder=""  ></textarea>
            {inputInfoAIError && (<span style={{ color: "#ff000096" }}>{inputInfoAIError}</span>)}
        </div>
        <div className="mb-4 text-sm px-3">
            <span className="mr-4 font-semibold">{props.data.selected_prod123[props.lang]}</span>
            <label className="mr-4">
                <input className="mr-1" type="radio" name="listProdRadio" value="sp1" checked={selectedProd === 'sp1'} onChange={e => setSelectedProd(e.target.value)} />
                <span>{cpViewSpStatusSp1 ? props.data.selected_prod1[props.lang] : props.data.form_input_2_sale5[props.lang]}</span>
            </label>
            <label className="mr-4">
                <input className="mr-1" type="radio" name="listProdRadio" value="sp2" checked={selectedProd === 'sp2'} onChange={e => setSelectedProd(e.target.value)} />
                <span>{cpViewSpStatusSp2 ? props.data.selected_prod2[props.lang] : props.data.form_input_2_sale6[props.lang]}</span>
            </label>
            <label className="mr-4">
                <input className="mr-1" type="radio" name="listProdRadio" value="sp3" checked={selectedProd === 'sp3'} onChange={e => setSelectedProd(e.target.value)} />
                <span>{cpViewSpStatusSp3 ? props.data.selected_prod3[props.lang] : props.data.form_input_2_sale7[props.lang]}</span>
            </label>
        </div>
        <br />CpResaleView:
        Vd: https://www.amazon.com/Amazon-Essentials-Classic-Fit-Short-Sleeve-T-Shirt/dp/B0775ZBQ9H/ref=sr_1_1?crid=2QO7QQKINPSWW&dib=eyJ2IjoiMSJ9.L30zJeumxUn530y501pgCFmiBNr-XzVClpNO-rVU1eBLrws0JNr0MIAYhPMKjOY3aAVyn_F8MF681QKyQYY7fptr-lrFnGS4qVq3BcIuXe3r5gRNAkWOREv9tMHNc0FboZfwDFHVKHHhJ0wS03TjWpBBORgfp1x_Bh-jMQG69cStqrtWA7uuagU0Df-XxQSIqqQwfRV9ZmyPusgm-nrEX-Ci4yXXj3EAWNR2DSp7aH6x_vYQ99NMzA_ziMs2MeR_8Umt6BBHbwn11Ecgh5LyxNoKKKbJ5sbN7VHqok5bvok.mTHnoWCL2CyhBIeRnrQxmLfbnsfKmm4xQQZJTbnpYsM&dib_tag=se&keywords=Amazon+Essentials+Women&qid=1725442207&sprefix=amazon+essentials+women%2Caps%2C476&sr=8-1
        <CpResaleView {...props} handleCpSale1={handleCpSale1} isDisplay={selectedProd === "sp1" ? false : true} sp_id={"sp1"}></CpResaleView>
        <CpResaleView {...props} handleCpSale1={handleCpSale1} isDisplay={selectedProd === "sp2" ? false : true} sp_id={"sp2"}></CpResaleView>
        <CpResaleView {...props} handleCpSale1={handleCpSale1} isDisplay={selectedProd === "sp3" ? false : true} sp_id={"sp3"}></CpResaleView>
    </div>
};

export default CpResale;
import React, { useEffect, useState } from 'react';
import CpHeader from "../component/cp_header"
import CpFooter from "../component/cp_footer"
import CpLoading from "../component/cp_loading"
import { loadContent } from "../lib/libFly";

function PBlogSingle(props) {
    const [data, setData] = useState();
    useEffect(() => {
        // alert(props.data_url.data_id);
        let aa_ = loadContent(props.data_url.data_id);
        aa_.then((result) => {
            console.log(result);
            if (result.title !== undefined && result.title[props.lang] !== undefined)
                document.title = result.title[props.lang];
            setData(result);
        })

        window.abcC();
    },);

    return (<React.Fragment>
        <div className="main">
            <CpHeader {...props}></CpHeader>
            <section className="pb-20">
                <div className="pt-20 pb-8 mb-12 bg-cover bg-no-repeat" style={{ "backgroundImage": "url('/assets/imgs/placeholders/img-14.jpg')" }}>
                    <div className="container">
                        <div className="max-w-2xl mx-auto">
                            <div className="text-center mb-6">
                                <span className="text-base md:text-lg">
                                    <a className="text-blueGray-200 hover:underline" href="/blog"><span className="inline-block py-1 px-3 text-xs font-semibold bg-blue-100 text-blue-600 rounded-xl mr-3">{data && data.cat[props.lang]}</span></a>
                                    {/* <span className="text-blueGray-200 text-sm">24 Jan, 2021</span> */}
                                </span>
                                {/* <h2 className="text-4xl md:text-5xl mt-4 text-white font-bold font-heading">{data && data.title[props.lang]}</h2> */}
                            </div>
                            {/* <div className="flex justify-center mb-8">
                                <img className="w-12 h-12 object-cover rounded-full" src={data && data.img[props.lang]} alt="" />
                                <div className="pl-4">
                                    <p className="text-blueGray-100 mb-1">Alice Bradley</p>
                                    <p className="text-xs text-blueGray-200 font-semibold">Author</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="max-w-2xl mx-auto">
                        <p className="mb-6 leading-loose text-blueGray-400 wow animate__animated animate__fadeIn animated" data-wow-delay=".1s">
                            {data && data.content[props.lang]}
                              
                        </p>
                    </div>
                </div>
            </section>
            <CpFooter {...props}></CpFooter>
        </div>
        <CpLoading {...props}></CpLoading>
    </React.Fragment >)
};

export default PBlogSingle
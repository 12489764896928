// import { NavLink } from 'react-router-dom';
import React from 'react';
const cp_header = (props) => (
    <header className="bg-transparent sticky-bar mt-4">
        <div className="container bg-transparent">
            <nav className="bg-transparent flex justify-between items-center py-3">
                <a className="text-3xl font-semibold leading-none" href="index.html">
                    <img className="h-10" src="/assets/imgs/logos/logo.png" alt="" />
                </a>
                <ul className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12">
                    {props.data_master.menu.header.map((item, i) => item.childs.length === 0 ? <li key={i} className="pt-4 pb-4">
                        <a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500" href={"/s" + item.link[props.lang]}>{item.title[props.lang]}</a>
                    </li> : <li key={i} className="group relative pt-4 pb-4 has-child">
                        <a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500" href={"/s" + item.link[props.lang]}>{item.title[props.lang]}</a>
                        <ul className="drop-down-menu min-w-200">
                            {item.childs.map((item_child, i_c) => <li key={i_c}>
                                <a className="menu-sub-item text-sm text-blueGray-600 hover:text-blueGray-500" href={"/s" + item_child.link[props.lang]}>{item_child.title[props.lang]}</a>
                            </li>)}
                        </ul>
                    </li>)}
                </ul>
                <div className="hidden lg:block">
                    {props.data_master["list-lang"].map((item, i) => item.code !== props.lang && <a key={i} className="btn-accent hover-up-2" href={"/s/" + item.code}>{item.title}</a>)}
                    {/* <a className="btn-accent hover-up-2" href="login.html">Log In</a>
                        <a className="btn-primary hover-up-2" href="signup.html">Sign Up</a> */}
                </div>
                <div className="lg:hidden">
                    <button className="navbar-burger flex items-center py-2 px-3 text-blue-500 hover:text-blue-700 rounded border border-blue-200 hover:border-blue-300">
                        <svg className="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Mobile menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                        </svg>
                    </button>
                </div>
            </nav>
        </div>
    </header>
);

export default cp_header;
// import { NavLink } from 'react-router-dom';
import React from 'react';
const cp_footer = (props) => (
    <section className="relative">
        <div className="hidden lg:block absolute inset-0 w-1/2 ml-auto bg-blueGray-50"></div>
        <div className="container">
            <div className="relative flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/2 pt-20 px-4 max-w-lg lg:max-w-full mx-auto lg:mx-0 text-center lg:text-left">
                    <a className="inline-block mx-auto lg:ml-0 text-3xl font-semibold leading-none wow animate__animatedanimated animate__fadeInUp animated" data-wow-delay=".1s" href="index.html">
                        <img className="h-10" src="/assets/imgs/logos/logo.png" alt="" />
                    </a>
                    <div className="mt-12 mb-16">
                        <p className="mb-4 text-xs text-blueGray-400 text-center lg:text-left wow animate__animatedanimated animate__fadeInUp animated" data-wow-delay=".1s">Subscribe and stay fully connected with product.</p>
                        <div className="flex flex-wrap lg:flex-nowrap items-center wow animate__animatedanimated animate__fadeInUp animated" data-wow-delay=".3s">
                            <div className="flex w-full lg:w-2/3 mb-3 lg:mb-0 px-4 bg-blueGray-50 rounded">
                                <svg className="h-6 w-6 my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                </svg>
                                <input className="w-full py-4 pl-3 text-xs text-blueGray-400 bg-blueGray-50 outline-none" type="text" placeholder="Type your e-mail" />
                            </div>
                            <button className="hover-up-2 w-full lg:w-auto py-4 px-8 lg:ml-6 text-xs text-white font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded" type="submit">Sign Up</button>
                        </div>
                    </div>
                    <div className="hidden lg:block wow animate__animatedanimated animate__fadeInUp animated" data-wow-delay=".5s">
                        <p className="text-xs text-blueGray-400">&copy; 2021. All rights reserved.</p>
                        <div className="-mx-2 mt-4">
                            <a className="inline-block px-2" href="/">
                                <img src="/assets/imgs/icons/facebook-blue.svg" alt="" />
                            </a>
                            <a className="inline-block px-2" href="/">
                                <img src="/assets/imgs/icons/twitter-blue.svg" alt="" />
                            </a>
                            <a className="inline-block px-2" href="/">
                                <img src="/assets/imgs/icons/instagram-blue.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="relative w-full lg:w-1/2 px-4 pt-40 lg:pl-12">
                    <div className="flex flex-row flex-wrap justify-around mb-12">
                        <div className="w-1/2 lg:w-auto mb-12 wow animate__animatedanimated animate__fadeInUp animated" data-wow-delay=".1s">
                            <h5 className="mb-6 text-lg font-bold font-heading">About</h5>
                            <ul className="space-y-3">
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="about.html">About us</a></li>
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="portfolio.html">Portfolio</a></li>
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="services.html">Services</a></li>
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="contact.html">Contact</a></li>
                            </ul>
                        </div>
                        <div className="w-1/2 lg:w-auto mb-12 wow animate__animatedanimated animate__fadeInUp animated" data-wow-delay=".3s">
                            <h5 className="mb-6 text-lg font-bold font-heading">Quick links</h5>
                            <ul className="space-y-3">
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="team.html">Team</a></li>
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="pricing.html">Pricing</a></li>
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="testimonials.html">Testimonials</a></li>
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="faqs.html">Faqs</a></li>
                            </ul>
                        </div>
                        <div className="w-full lg:w-auto mb-4 wow animate__animatedanimated animate__fadeInUp animated" data-wow-delay=".5s">
                            <h5 className="mb-6 text-lg font-bold font-heading">Blog</h5>
                            <ul className="space-y-3">
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="blog.html">Bussiness</a></li>
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="blog-2.html">Marketing</a></li>
                                <li><a className="text-lg text-blueGray-400 hover:text-blueGray-500" href="blog-single.html">Single</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="lg:hidden text-center">
                        <p className="text-xs text-blueGray-400">&copy; 2021. All rights reserved.</p>
                        <div className="-mx-2 mt-4">
                            <a className="inline-block px-2" href="/">
                                <img src="/assets/imgs/icons/facebook-blue.svg" alt="" />
                            </a>
                            <a className="inline-block px-2" href="/">
                                <img src="/assets/imgs/icons/twitter-blue.svg" alt="" />
                            </a>
                            <a className="inline-block px-2" href="/">
                                <img src="/assets/imgs/icons/instagram-blue.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default cp_footer;
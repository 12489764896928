import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from "./pages/p_home";
import P404 from "./pages/p_404";
import PAbout from "./pages/p_about";
import PBlog from "./pages/p_blog";
import PBlogSingle from "./pages/p_blog_single.js";



// import PContact from "./pages/p_contact";
// import POffice365 from "./pages/p_office_365";
// import PProducts from "./pages/p_products";
// import PProduct from "./pages/p_product";
// import PProductSaleOff from "./pages/p_product_sale_off";
import { BrowserRouter, Route, Routes } from "react-router-dom";

var ListAllPage = {
  "Home": Home, "P404": P404, "About": PAbout, "Blog": PBlog, "BlogSingle": PBlogSingle,
};

var data_master = null;
var prod = null;

//ngôn ngữ đang chọn
var lang_ = "";

//thuộc tính hiển thị, xem ở readUrl
var page_view = "";

//data yêu cầu
var data_id = "";

let data_url = readUrl();

// let data_cat_content = {};

//cờ để xác định là dev
//giúp code được các trường hợp đặt thù nhưng khi go-live cũng không bị lỗi
var isDev = false;
if (window.location.hostname === "localhost")
  isDev = true;

function readUrl() {
  //phân tích url để đưa ra các thông tin phù hợp dữ liệu quy định
  //cấu trúc:
  //1-> s/[lang]/c/[tiêu đề không dấu]-[mã bài].html          => xem 1 bài cụ thể với mã bài
  //2-> s/[lang]/lc/[tiêu đề không dấu]-[mã nhóm bài].html  => xem danh sách bài
  //3-> s/[lang]                                            => xem trang chủ theo ngôn ngữ chỉ định
  //4-> s/[lang]/p/[tiêu đề không dấu]-[mã sản phẩm].html   => xem 1 sản phẩm cụ thể
  //5-> s/[lang]/lp/[tiêu đề không dấu]-[mã nhóm].html      => xem 1 nhóm sản phẩm cụ thể

  let data_url = {}
  const d_ = window.location.pathname.split("/");
  for (let i_d in d_) {
    //theo thứ tự sẽ là các giá trị phù hợp
    if (i_d === "0" || i_d === "1")
      continue;//bỏ qua
    else if (i_d === "2")
      lang_ = d_[i_d];//ngôn ngữ
    else if (i_d === "3") {
      page_view = d_[i_d];//hiển thị
      data_url["page_view"] = page_view;
    }
    else if (i_d === "4") {
      //bỏ tiêu đề và .html
      let str_ = d_[i_d];
      str_ = str_.replace(".html", "");
      data_id = str_.split("-").pop();
      data_url["data_id"] = data_id;
      // console.log("data_id--->", data_id)
    }

  }
  // console.log("data_url--->", data_url)
  return data_url;
}

//fetch nhiều file cùng 1 lúc 
function fetchMuti(arr_) {
  for (let x of arr_) {
    fetch('/data/' + x, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
    )
      .then((response) => {
        return response.json();
      })
      // eslint-disable-next-line
      .then((data_) => {
        if (x === "master.json")
          data_master = data_;

        if (data_master !== null)
          renderOk()
      });
  }
}

// function utf8ToUrl(str) {
//   if (typeof str !== 'string') {
//     return '';
//   }

//   return str
//     .toLowerCase()
//     .trim()
//     .replace(/[^\w\s-]/g, '')
//     .replace(/\s+/g, '-')
//     .replace(/-+/g, '-');

// };

function renderOk() {
  if (data_master["list-lang"] !== undefined) {
    //lấy ngông ngữ mặc định
    let lang_default = "";
    for (let i_l in data_master["list-lang"])
      if (data_master["list-lang"][i_l].default && data_master["list-lang"][i_l].code !== undefined)
        lang_default = data_master["list-lang"][i_l].code;

    if (lang_ !== "") {
      //nếu ngôn ngữ yêu cầu không có trong danh sách thì chuyển về ngôn ngữ mặc định
      let hasLang = false;
      for (let i_l in data_master["list-lang"])
        if (data_master["list-lang"][i_l].code !== undefined && lang_ === data_master["list-lang"][i_l].code)
          hasLang = true;

      if (hasLang === false)
        window.location.href = "/" + lang_default;
    }
    else//dùng ngôn ngữ mặc định
      lang_ = lang_default
  }
  ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Home isDev={isDev} data_master={data_master} data_prod={prod} lang={lang_} data_url={data_url} />} />
          {/* Trang chủ với đủ ngôn ngữ đang có */}
          {data_master["list-lang"].map((item, i) => <Route key={i} path={"/s/" + item.code} element={<Home isDev={isDev} data_master={data_master} data_prod={prod} lang={lang_} data_url={data_url} />} />)}

          {/* root menu */}
          {data_master.menu.header.map((item, i) => <Route key={i} path={"/s" + item.link[lang_]} element={React.createElement(ListAllPage[item.code], {
            data_master: data_master,
            data_prod: prod,
            lang: lang_,
            data_url: data_url,
            isDev: isDev
          })} />)}
          {/* // <ListAllPage[""] data_master = { data_master } data_prod = { prod } lang = { lang_ } data_url = { data_url } />} />)} */}

          {/* child menu */}
          {data_master.menu.header.map((item, i) => item.childs.map((item_c, i_c) => <Route key={i_c} path={"/s" + item_c.link[lang_]} element={<Home isDev={isDev} data_master={data_master} data_prod={prod} lang={lang_} data_url={data_url} />} />))}


          {data_master.menu.hidden.map((item, i) =>
            <Route
              key={i}
              path={'/s/' + lang_ + '/c/*'}
              element={React.createElement(ListAllPage[item.code], {
                data_master: data_master,
                data_prod: prod,
                lang: lang_,
                data_url: data_url,
                isDev: isDev
              })}
            />)}
          <Route path="*" element={<P404 isDev={isDev} to="/404" replace />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

fetchMuti(["master.json"]);
import React, { useEffect, useState } from 'react';
import CpHeader from "../component/cp_header"
import CpFooter from "../component/cp_footer"
import CpLoading from "../component/cp_loading"
import { loadContent } from "../lib/libFly";

function PBlog(props) {
    const [data, setData] = useState();
    useEffect(() => {
        let aa_ = loadContent("blog");
        aa_.then((result) => {
            if (result.title !== undefined && result.title[props.lang] !== undefined)
                document.title = result.title[props.lang];
            setData(result);
        })

        window.abcC();
    });

    const utf8ToUrl = (str) => {
        if (typeof str !== 'string') {
            return '';
        }

        return str
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');

    };
    return (<React.Fragment>
        <div className="main">
            <CpHeader {...props}></CpHeader>
            <section class="py-20">
                <div class="container">
                    <h2 class="mb-12 lg:mb-20 text-3xl md:text-4xl font-bold font-heading">{data && data.heading[props.lang]}</h2>

                    {data && data.list_blog_single.map((item, i) =>
                        i % 2 === 0 ?
                            <div key={i} class="flex flex-wrap -mx-4 mb-12 wow animate__animated animate__fadeIn animated hover-up-5">
                                <div class="w-full lg:w-1/2 px-4 lg:pr-20 lg:pt-4 order-1 lg:order-0">
                                    <span class="inline-block py-1 px-3 uppercase text-xs font-semibold bg-blue-50 rounded-full text-blue-600">{item.cat[props.lang]}</span>
                                    {/* <h3 class="my-4 text-xl md:text-2xl font-bold font-heading"><a href="blog-single.html">{item.title[props.lang]}</a></h3> */}
                                    <p class="mb-4 text-sm md:text-base leading-loose text-blueGray-400">{item.content[props.lang]}</p>
                                    <a class="text-xs font-semibold text-blue-600" href={"/s/" + props.lang + "/c/" + utf8ToUrl(item.title[props.lang]) + "-" + item.id + ".html"}>
                                        <span>{data && data.readmore_0[props.lang]}</span>
                                        <svg class="inline-block w-4 h-4 ml-1" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                                        </svg>
                                    </a>
                                </div>
                                <div class="w-full lg:w-1/2 px-4 mb-8 order-0 lg:order-1">
                                    <img className="h-80 w-full object-cover rounded" src={item.img[props.lang]} alt={item.title[props.lang]} />
                                </div>
                            </div>
                            : <div key={i} class="flex flex-wrap -mx-4 mb-12 wow animate__animated animate__fadeIn animated hover-up-5">
                                <div class="w-full lg:w-1/2 px-4 mb-8 order-0 lg:order-1">
                                    <img className="h-80 w-full object-cover rounded" src={item.img[props.lang]} alt={item.title[props.lang]} />
                                </div>
                                <div class="w-full lg:w-1/2 px-4 lg:pr-20 lg:pt-4 order-1 lg:order-0">
                                    <span class="inline-block py-1 px-3 uppercase text-xs font-semibold bg-blue-50 rounded-full text-blue-600">{item.cat[props.lang]}</span>
                                    {/* <h3 class="my-4 text-xl md:text-2xl font-bold font-heading"><a href="blog-single.html">{item.title[props.lang]}</a></h3> */}
                                    <p class="mb-4 text-sm md:text-base leading-loose text-blueGray-400">{item.content[props.lang]}</p>
                                    <a class="text-xs font-semibold text-blue-600" href={"/s/" + props.lang + "/c/" + utf8ToUrl(item.title[props.lang]) + "-" + item.id + ".html"}>
                                        <span>{data && data.readmore_0[props.lang]}</span>
                                        <svg class="inline-block w-4 h-4 ml-1" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                    )}

                </div>
            </section>
            <CpFooter {...props}></CpFooter>
        </div>
        <CpLoading {...props}></CpLoading>
    </React.Fragment >)
};

export default PBlog
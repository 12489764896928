import React, { useEffect, useState } from 'react';
import chatAI from "../chat_ai/obChatAI";

var chatAI_ob = new chatAI();
function CpResaleView(props) {
    const [spUrl, setSpUrl] = useState("");
    const [spSKU, setSpSKU] = useState("");
    const [spError, setSpError] = useState("");
    const [spTitle, setSpTitle] = useState("");
    const [spBrand, setSpBrand] = useState("");
    const [spShopLocation, setSpShopLocation] = useState("");
    const [spDes, setSpDes] = useState("");
    const [spImg, setSpImg] = useState("");

    const handlegetUrlMaster = () => {
        setSpError("");
        setSpTitle("");
        setSpBrand("");
        setSpShopLocation("");
        setSpDes("");
        setSpImg("");
        setSpSKU("");

        let str_spUrl = spUrl.trim();
        if (str_spUrl === "") {
            setSpError(props.data.sp_sp_error1[props.lang]);
            return;
        }

        let list_check = ["shopee.vn", "lazada.vn", "tiki.vn", "amazon.com"];
        let link_domain = "";
        if (!list_check.some((x) => {
            if (str_spUrl.includes(x) === true) {
                link_domain = x;
                return true;
            }
            return false;
        })) {
            setSpError(props.data.sp_sp_error2[props.lang]);
            return;
        }

        if (link_domain === "")
            return;

        fetch('/data/amazon.txt', {
            headers: {}
        }
        ).then((response) => response.text())
            .then((text) => {
                console.log(text);
                amazonLink(text);
            });



        // var xmlhttp;
        // xmlhttp = new XMLHttpRequest();
        // xmlhttp.onreadystatechange = () => {
        //     if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        //         let txt_ = xmlhttp.responseText;
        //         console.log("txt_===>", txt_);
        //         if (link_domain === "shopee.vn")
        //             shopeeLink(txt_);
        //         else if (link_domain === "lazada.vn")
        //             lazadaLink(txt_);
        //         else if (link_domain === "tiki.vn")
        //             tikiLink(txt_);
        //         else if (link_domain === "amazon.com")
        //             amazonLink(txt_);
        //         else
        //             noneLink();

        //     }
        //     // else
        //     //     console.log("===>", xmlhttp.readyState, "==", xmlhttp.responseText)
        // }
        // xmlhttp.open("GET", spUrl, true);
        // xmlhttp.send();
    }
    const noneLink = () => {

    }
    const lazadaLink = (txt_) => {
        //Tấn
    }
    const amazonLink = (txt_) => {
        console.log(txt_)

        /*
        Tạm thời đang lấy ổn định
        Title: Lấy được title trên nhiều sản phẩm khác nhau
        Brand: Lấy được brand trên nhiều sản phẩm khác nhau
        SKU: Lấy được SKU trên nhiều sản phẩm khác nhau
        Shop location: Amazon không cung cấp thông tin vị trí cửa hàng
        
        Description: Chưa hoàn thành (Mỗi trang Amazon có cấu trúc khác nhau, nên cần phải xác định cấu trúc trang hiện tại để lấy thông tin mô tả sản phẩm)

        Image URL: Lấy được URL ảnh trên nhiều sản phẩm khác nhau
        */

        /*
        1/ hoàn thành việc khai báo và xử lý ở 1 vùng: vùng trên cùng là khai báo biến và xử lý chặn (có mô tả biến và lý do), vùng xử lý logic (có mô tả biến và lý do)
        2/ try và gửi lỗi logic đến 1 kênh chung là telegram mặc định là thanhlm22 hoặc mã id 6410912083
        */

        //1. Title
        // Xây dựng hàm decodeHtmlCharCodes để giải mã các ký tự HTML
        // VD: Ký tự: &#39; -> '
        function decodeHtmlCharCodes(str) { 
            return str.replace(/(&#(\d+);)/g, function(match, capture, charCode) {
                return String.fromCharCode(charCode);
            });
        }
            
        // <span id="productTitle" class="a-size-large product-title-word-break">Amazon Essentials Women&#39;s Classic-Fit Short-Sleeve V-Neck T-Shirt, Multipacks</span>
        // Lấy ra title của sản phẩm bằng cách cắt chuỗi theo điều kiện
        // <span id="productTitle" class=".*?">(.*?)<\/span> 
        // Trong đó:
        // - <span id="productTitle" class=".*?">: chuỗi bắt đầu
        // - (.*?): nội dung cần lấy
        // - <\/span>: chuỗi kết thúc             
        const titleMatch = txt_.match(/<span id="productTitle" class=".*?">(.*?)<\/span>/);
        if (titleMatch === undefined){
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }
        // .trim() bỏ dấu cách ở đầu và cuối chuỗi
        const title = decodeHtmlCharCodes(titleMatch ? titleMatch[1].trim() : "");

        // 2. SKU (ASIN for Amazon)
        // "asin" ?: ?"(\w+)"
        // Trong đó:
        // - "asin" ?: ?: chuỗi bắt đầu
        // - "(\w+)": nội dung cần lấy
        // - \w: ký tự chữ cái hoặc số
        // - +: 1 hoặc nhiều lần
        const skuMatch = txt_.match(/"asin" ?: ?"(\w+)"/);
        if (skuMatch === undefined){
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }

        const sku = skuMatch ? skuMatch[1] : "";

        // 3. brand
        // <a id="bylineInfo" class=".*?">(.*?)<\/a>
        // Trong đó:
        // - <a id="bylineInfo" class=".*?">: chuỗi bắt đầu
        // - (.*?): nội dung cần lấy
        // - <\/a>: chuỗi kết thúc
        const brandMatch = txt_.match(/<a id="bylineInfo" class=".*?">(.*?)<\/a>/);
        const brand = brandMatch ? brandMatch[1].trim() : "";

        // 4. shop location
        // Vì amazon không cung cấp thông tin vị trí cửa hàng
        const shopLocation = "";

        // 5. description
        const descMatch = txt_.matchAll(/span class="a-list-item a-size-base a-color-base.*?">(.*?)<\/span>/g);
        const desc = []

        // const description = descMatch ? descMatch[1] : "";
        for (const match of descMatch) 
            desc.push(match[1])
        // const description = desc.join('\n')

        //Paser text to element: // document.getElementById('feature-bullets').textContent
        const parser = new DOMParser();
        const doc = parser.parseFromString(txt_, 'text/html');
        const featureBullets = doc.getElementById('feature-bullets');

        // Lấy nội dung từ phần tử 'feature-bullets' nếu có
        let description = '';

        // Kiểm tra xem featureBullets có tồn tại hay không trước khi thao tác
        if (featureBullets && featureBullets.querySelectorAll) {
            const listItems = featureBullets.querySelectorAll('li');
            
            // Kiểm tra nếu listItems có tồn tại và có độ dài hợp lệ
            if (listItems.length > 0) {
                description = [...listItems].map(item => item.innerText.trim()).join('\n');
            }
        }

        // Nếu không có featureBullets hoặc không có các li, sử dụng desc fallback từ regex
        if (!description) {
            description = desc.join('\n') || '';
        }


        // 6. image URL
        // <script type="a-state" data-a-state="{&quot;key&quot;:&quot;desktop-landing-image-data&quot;}">{"landingImageUrl":"https://m.media-amazon.com/images/I/61VXOrqsTyS.__AC_SY445_SX342_QL70_FMwebp_.jpg"}</script>
        // Lấy ra URL ảnh của sản phẩm bằng cách căt chuỗi theo điều kiện
        // "landingImageUrl":"(.*?)"
        // Trong đó:
        // - "landingImageUrl": chuỗi bắt đầu
        // - (.*?): nội dung cần lấy
        const imgMatch = txt_.match(/"landingImageUrl":"(.*?)"/);
        const img = imgMatch ? imgMatch[1] : "";

        // Set the state values
        setSpSKU(sku);
        setSpError("");
        setSpTitle(title);
        setSpBrand(brand);
        setSpShopLocation(shopLocation);
        setSpDes(description.replaceAll('\n', '<br />'));
        setSpImg(img);

        // Build the product object
        let ob__ = chatAI_ob.buildProd({
            "title": (title ? title : props.data.sp_none[props.lang]),
            "sku": (sku ? sku : props.data.sp_none[props.lang]),
            "option1": (brand ? brand : props.data.sp_none[props.lang]),
            "option2": (shopLocation ? shopLocation : props.data.sp_none[props.lang]),
            "description": (description ? description : props.data.sp_none[props.lang]),
            "img_main": (img ? img : props.data.sp_none[props.lang]),
        });

        // gọi hàm handleCpSale1 để cập nhật thông tin sản phẩm
        props.handleCpSale1(props.sp_id, ob__);

    };
    
    const tikiLink = (txt_) => {
        //Toàn
    }
    const shopeeLink = (txt_) => {
        //các bước cắt để lấy ra data json phù hợp -> có title sản phẩm 
        //1 cắt `script type="text/mfe-initial-data" data-module=`
        const strp_p_1 = txt_.split(`script type="text/mfe-initial-data" data-module=`)
        if (strp_p_1 === undefined) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }

        //2 tìm đến dấu `{` gần nhất
        if (strp_p_1.length <= 1) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }

        var strp_p_2 = strp_p_1[1].split(`{`)
        strp_p_2.splice(0, 1);

        var strp_p_2_new = "{" + strp_p_2.join('{')
        if (strp_p_2_new === "") {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }
        //3 cắt </script> gần nhất
        var strp_p_3 = strp_p_2_new.split(`</script>`)
        if (strp_p_3.length <= 0) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }

        var json__ = JSON.parse(strp_p_3[0]);

        var shepee_itemId = json__["initialState"]["DOMAIN_CONTEXT"]["data"]["itemId"];
        if (shepee_itemId.length <= 0) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }

        var item__ = json__["initialState"]["item"]["items"][shepee_itemId];

        // console.log("==item__===>", item__); 
        // nếu có lỗi thì return và thông báo lỗi 
        //không chạy hết hàm 
        setSpSKU("" + shepee_itemId);
        setSpError("");
        setSpTitle(item__.name);
        setSpBrand(item__.brand);
        setSpShopLocation(item__.shop_location);
        setSpDes(item__.description.replaceAll('\n', '<br />'));
        setSpImg("https://down-vn.img.susercontent.com/file/" + item__.image);

        let ob__ = chatAI_ob.buildProd({
            "title": (item__.name ? item__.name : props.data.sp_none[props.lang]),
            "sku": (shepee_itemId !== "" ? shepee_itemId : props.data.sp_none[props.lang]),
            "option1": (item__.sp_brand ? item__.sp_brand : props.data.sp_none[props.lang]),
            "option2": (item__.shop_location ? item__.shop_location : props.data.sp_none[props.lang]),
            "description": (item__.description ? item__.description : props.data.sp_none[props.lang]),
            "img_main": (item__.image ? " https://down-vn.img.susercontent.com/file/" + item__.image : props.data.sp_none[props.lang]),
        });
        props.handleCpSale1(props.sp_id, ob__);
    }
    useEffect(() => {
    });

    return <div className="w-full lg:w-1 px-3 mb-4 lg:mb-0" style={{ "display": props.isDisplay ? "none" : "" }}>
        <span className="mr-4">{props.data.sp_support[props.lang]}</span> <br />
        <span className="mr-4">{props.data.sp_support1[props.lang]}</span>
        <div className="mb-4">
            <label className="flex px-2 bg-blueGray-50 rounded">
                <input className="w-full p-4 text-xs font-semibold leading-none bg-blueGray-50 rounded outline-none" type="text" value={spUrl} onChange={e => setSpUrl(e.target.value)} />
                <span className={"bg-blue-500 hover:bg-blue-600 my-1 ml-auto px-4 py-3 text-xs text-white font-semibold leading-none rounded cursor-pointer"} onClick={handlegetUrlMaster}>{props.data.sp_btn[props.lang]}</span>
            </label>
            {spError && spError.length > 0 && <span style={{ "color": "#ff000096" }}>{spError}</span>}
            {spImg && spImg.length > 0 && <React.Fragment><img src={spImg} style={{ height: "150px" }} alt=''></img><br /></React.Fragment>}
            {spTitle && spTitle.length > 0 && <React.Fragment><span style={{ "fontWeight": "bold" }}>{props.data.sp_title[props.lang]}{spTitle}</span><br /></React.Fragment>}
            {spBrand && spBrand.length > 0 && <React.Fragment><span>{props.data.sp_brand[props.lang]}{spBrand}</span><br /></React.Fragment>}
            {spSKU && spSKU.length > 0 && <React.Fragment><span>{props.data.sp_sku[props.lang]}{spSKU}</span><br /></React.Fragment>}
            {spShopLocation && spShopLocation.length > 0 && <React.Fragment><span>{props.data.sp_shop_location[props.lang]}{spShopLocation}</span><br /></React.Fragment >}
            {spDes && spDes.length > 0 && <span dangerouslySetInnerHTML={{ __html: props.data.sp_des[props.lang] + spDes }}></span>}
        </div>
    </div>
};

export default CpResaleView;
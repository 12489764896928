// import { NavLink } from 'react-router-dom';
import React from 'react';
const cp_loading = (props) => (
    <div id="preloader-active">
        <div className="preloader flex-1 content-center">
            <div className="logo absolute inset-y-2/4 jump">
                <img src="/assets/imgs/logos/logo.png" alt="" />
                <h1 className="text-lg font-semibold">Sóc bay</h1>
            </div>
        </div>
    </div>
);

export default cp_loading;